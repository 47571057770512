<div *ngIf="refDataLoaded">
    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.address.street.label' | translate }}" [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="driverAddressStreet" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                              maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                              placeholderLabel="{{ 'screens.driver.management.driver.address.street.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverAddressStreet.touched">
            <ux-control-feedback *ngIf="driverAddressStreet.hasError('required')" typeClass="danger">
                {{ 'screens.driver.management.driver.address.street.validation.error.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.address.postCode.label' | translate }}" [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="driverAddressPostCode" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                              maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                              placeholderLabel="{{ 'screens.driver.management.driver.address.postCode.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverAddressPostCode.touched">
            <ux-control-feedback *ngIf="driverAddressPostCode.hasError('required')" typeClass="danger">
                {{ 'screens.driver.management.driver.address.postCode.validation.error.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.address.city.label' | translate }}" [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="driverAddressCity" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                              maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                              placeholderLabel="{{ 'screens.driver.management.driver.address.city.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverAddressCity.touched">
            <ux-control-feedback *ngIf="driverAddressCity.hasError('required')" typeClass="danger">
                {{ 'screens.driver.management.driver.address.city.validation.error.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.address.country.label' | translate }}" [isRequired]="!isReadOnly">
            <eui-autocomplete isItemsSorted [readonly]="isReadOnly" [formControl]="driverAddressCountry" [autocompleteData]="worldCountries" [itemsSortOrder]="'ASC'"
                              placeholder="{{ isReadOnly ? '' : 'screens.common.placeholder.select' | translate }}">
            </eui-autocomplete>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverAddressCountry.touched">
            <ux-control-feedback *ngIf="driverAddressCountry.hasError('required')" typeClass="danger">
                {{ 'screens.driver.management.driver.address.country.validation.error.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>
</div>
