<div *ngIf="refDataLoaded">
    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.transport.manager.address.street.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="transportManagerAddressStreet" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                placeholderLabel="{{ 'screens.account.management.transport.manager.address.street.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || transportManagerAddressStreet.touched">
            <ux-control-feedback *ngIf="transportManagerAddressStreet.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.transport.manager.address.postCode.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="transportManagerAddressPostCode" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                placeholderLabel="{{ 'screens.account.management.transport.manager.address.postCode.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || transportManagerAddressPostCode.touched">
            <ux-control-feedback *ngIf="transportManagerAddressPostCode.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.transport.manager.address.city.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="transportManagerAddressCity" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                placeholderLabel="{{ 'screens.account.management.transport.manager.address.city.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || transportManagerAddressCity.touched">
            <ux-control-feedback *ngIf="transportManagerAddressCity.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.transport.manager.address.country.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <eui-autocomplete isItemsSorted [readonly]="isReadOnly" [formControl]="transportManagerAddressCountry"
                              [autocompleteData]="postingCountries" [itemsSortOrder]="'ASC'"
                              placeholder="{{ isReadOnly ? '' : 'screens.common.placeholder.select' | translate }}">
            </eui-autocomplete>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors
                                            || transportManagerAddressCountry.hasError('requiredValidCountry')
                                            || transportManagerAddressCountry.hasError('validCountry')">
            <ux-control-feedback *ngIf="transportManagerAddressCountry.hasError('requiredValidCountry')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
            <ux-control-feedback *ngIf="transportManagerAddressCountry.hasError('validCountry')" typeClass="danger">
                {{ 'screens.common.message.field.valid.country' | translate }}
            </ux-control-feedback>
        </div>
    </div>
</div>
