import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from '../helpers/null-value.util';

export const flagIconRoundedClass = 'eui-flag-icon eui-flag-icon--rounded eui-flag-icon-';

export function formatForAutoComplete(translateService?: TranslateService, iconClass = undefined) {
    return map((refData: any[]) => {
        return (refData || []).map(ref => formatAsAutoCompleteItem(ref, ref.key, translateService, iconClass));
    });
}

export function formatAsAutoCompleteItem(
    ref: any, labelKey: string, translateService?: TranslateService, iconClass = undefined): any {
    if (Array.isArray(ref)) {
        return ref.map(refItem => formatAsAutoCompleteItem(refItem, labelKey, translateService));
    }
    if (isEmpty(ref)) {
        return { id: null, label: '' };
    }
    let formattedItem: any;
    const iconClassSuffix = ref?.code || ref?.id || ref;

    if (typeof ref === 'string') {
        formattedItem = {
            id: ref,
            label: labelKey ? translateService.instant(labelKey + `${ref.toLowerCase()}`) : ref,
        };
    } else if (typeof ref === 'object') {
        formattedItem = {
            ...ref,
            id: ref.code || ref.id,
            label: translateService.instant(ref.key),
        };
        if (ref.iconClassSuffix) {
            delete formattedItem.iconClassSuffix;
        }
    }
    if (iconClass) {
        formattedItem = {
            ...formattedItem,
            iconClass: iconClass + `${iconClassSuffix.toLowerCase()}`,
        };
    }
    return formattedItem;
}

export function formatMessageTypes() {
    return map((refData: any[]) => {
        return (refData || []).reduce((messages, messageType) => {
                return {
                    ...messages,
                    [messageType.code]: {
                        key: messageType.key,
                        defaultMessageKey: messageType.defaultMessageKey,
                        subTypes: messageType.subTypes ? ((messageType.subTypes || []).reduce((subTypes, subType) => {
                            return {
                                ...subTypes,
                                [subType.code] : subType.key,
                            };
                        }, {})) : null,
                    },
                };
            }, {},
        );
    });
}

@Injectable({ providedIn: 'root' })
export class RefDataService {
    private shareReplay: { [uri: string]: Observable<Object[]> } = {};

    public constructor(
        private httpClient: HttpClient,
        private translateService: TranslateService,
    ) {
    }

    public getMSCountries(): Observable<Object[]> {
        return this.httpShareReplay('assets/refdata/msCountries.json');
    }

    public get formattedMSCountries$(): Observable<Object[]> {
        return this.getMSCountries().pipe(
            formatForAutoComplete(this.translateService, flagIconRoundedClass),
        );
    }

    public getUKCountry(): Observable<Object> {
        return this.httpShareReplay('assets/refdata/ukCountry.json');
    }

    public getMSUKCountries(): Observable<Object[]> {
        return zip(
            this.getMSCountries(),
            this.getUKCountry(),
        ).pipe(
            map(([msCountries, ukCountry]) => {
                return (msCountries || []).concat(ukCountry).filter(n => n);
            }),
        );
    }

    public get formattedMSUKCountries$(): Observable<Object[]> {
        return this.getMSUKCountries().pipe(
            formatForAutoComplete(this.translateService, flagIconRoundedClass),
        );
    }

    public getWorldCountries(): Observable<Object[]> {
        return this.httpShareReplay('assets/refdata/worldCountries.json');
    }

    public get formattedWorldCountries$(): Observable<Object[]> {
        return this.getWorldCountries().pipe(
            formatForAutoComplete(this.translateService, flagIconRoundedClass),
        );
    }

    public getDocumentTypes(): Observable<Object> {
        return this.httpShareReplay('assets/refdata/documentTypes.json');
    }

    public get formattedDocumentTypes$(): Observable<Object[]> {
        return this.getDocumentTypes().pipe(
            formatForAutoComplete(this.translateService),
        );
    }

    public getOperationTypes(): Observable<Object> {
        return this.httpShareReplay('assets/refdata/operationTypes.json');
    }

    public get formattedOperationTypes$(): Observable<Object[]> {
        return this.getOperationTypes().pipe(
            formatForAutoComplete(this.translateService),
        );
    }

    public getTransportTypes(): Observable<Object> {
        return this.httpShareReplay('assets/refdata/transportTypes.json');
    }

    public get formattedTransportTypes$(): Observable<Object[]> {
        return this.getTransportTypes().pipe(
            formatForAutoComplete(this.translateService),
        );
    }

    public getLanguages(): Observable<Object[]> {
        return this.httpShareReplay('assets/refdata/languages.json');
    }

    public get formattedLanguages$(): Observable<Object[]> {
        return this.getLanguages().pipe(
            formatForAutoComplete(this.translateService),
        );
    }

    public getMessageTypes(): Observable<Object[]> {
        return this.httpShareReplay('assets/refdata/messageTypes.json');
    }

    public get formattedMessageTypes$(): Observable<Object[]>{
        return this.getMessageTypes().pipe(formatMessageTypes());
    }

    private httpShareReplay(refDataUrl: string): Observable<Object[]>{
       if (!this.shareReplay[refDataUrl]) {
            this.shareReplay[refDataUrl] = this.httpClient.get(refDataUrl).pipe(
                shareReplay(1),
            ) as Observable<Object[]>;
        }
        return this.shareReplay[refDataUrl];
    }
}
