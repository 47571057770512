<div *ngIf="refDataLoaded">
    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.company.address.street.label' | translate }}"
            [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="operatorAddressStreet" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                placeholderLabel="{{ 'screens.account.management.company.address.street.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || operatorAddressStreet.touched">
            <ux-control-feedback *ngIf="operatorAddressStreet.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.company.address.postCode.label' | translate }}"
            [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="operatorAddressPostCode" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                placeholderLabel="{{ 'screens.account.management.company.address.postCode.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || operatorAddressPostCode.touched">
            <ux-control-feedback *ngIf="operatorAddressPostCode.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.company.address.city.label' | translate }}"
            [isRequired]="!isReadOnly">
            <ux-form-control rtpdTrimInput [formControl]="operatorAddressCity" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                placeholderLabel="{{ 'screens.account.management.company.address.city.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || operatorAddressCity.touched">
            <ux-control-feedback *ngIf="operatorAddressCity.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.company.address.country.label' | translate }}"
            [isRequired]="!isReadOnly">
            <eui-autocomplete isItemsSorted [readonly]="isReadOnly" [formControl]="operatorAddressCountry"
                [autocompleteData]="postingCountries" [itemsSortOrder]="'ASC'"
                placeholder="{{ isReadOnly ? '' : 'screens.common.placeholder.select' | translate }}">
            </eui-autocomplete>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors
                                || operatorAddressCountry.hasError('requiredValidCountry')
                                || operatorAddressCountry.hasError('validCountry')">
            <ux-control-feedback *ngIf="operatorAddressCountry.hasError('requiredValidCountry')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
            <ux-control-feedback *ngIf="operatorAddressCountry.hasError('validCountry')" typeClass="danger">
                {{ 'screens.common.message.field.valid.country' | translate }}
            </ux-control-feedback>
        </div>
    </div>
</div>
