import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rtpd-time-zone',
  templateUrl: './time-zone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeZoneComponent implements OnInit, OnDestroy {

    public timeZoneDisplay: string | undefined;

    private _onDestroy$ = new Subject<void>();

    public constructor(
        private translateService: TranslateService,
    ) {}

    public ngOnInit() {
        this.translateService.onLangChange.pipe(
            startWith({ lang: this.translateService.currentLang }),
            takeUntil(this._onDestroy$),
        ).subscribe(current => {
            this.setTimezoneDisplay(current.lang);
        });
    }

    public setTimezoneDisplay(lang: string) {
        const tzOffset = this.getTimezoneOffset();
        const tzName = this.getTimezoneName(lang);
        if (tzOffset || tzName) {
            this.timeZoneDisplay = `(${tzOffset} - ${tzName}).`;
        } else {
            this.timeZoneDisplay = undefined;
        }
    }

    public getTimezoneOffset(locale = 'en'): string | undefined {
        if (Intl) {
            const formatter = new Intl.DateTimeFormat(locale, {
                timeZoneName: 'longOffset',
            });
            return formatter
                .formatToParts()
                .find(part => part.type === 'timeZoneName')
                .value
                .replace('GMT', 'UTC');

        }
        return undefined;
    }

    public getTimezoneName(locale: string): string | undefined {
        if (Intl) {
            const formatter = new Intl.DateTimeFormat(locale, {
                timeZoneName: 'long',
            });
            return formatter
                .formatToParts()
                .find(part => part.type === 'timeZoneName')
                .value;
        }
        return undefined;
    }

    public ngOnDestroy() {
        this._onDestroy$.next();
    }
}
