import { Component, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { hasInputChanged } from '@rtpd/shared';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'rtpd-show-date-or-datepicker',
  templateUrl: './show-date-or-datepicker.component.html',
})
export class ShowDateOrDatepickerComponent implements OnDestroy {
    @Input() public isReadOnly: boolean;
    @Input() public form: FormGroup;
    @Input() public control: FormControl;
    @Input() public dateValue: string;
    @Input() public minDate: Date;
    @Input() public maxDate: Date;
    @Input() public dateFormat: string;
    private _onDestroy$ = new Subject<void>();
    private _valueChangesSubscription: Subscription;

    public constructor() {
        this.dateFormat = this.dateFormat ? this.dateFormat : 'dd/MM/yyyy';
    }

    public ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    public ngOnChanges(changes: SimpleChanges) {
        // work around for ux-datepicker not properly updating 'touched'
        if (hasInputChanged(changes.control) || hasInputChanged(changes.isReadOnly) ) {
            if (this.control && this._valueChangesSubscription == null && !this.isReadOnly) {
                this.markInvalidControlAsTouched();
                this._valueChangesSubscription = this.subscribeControlValueChanges();
            }
        }
    }

    public getValueChangesSubscription(): Subscription {
        return this._valueChangesSubscription;
    }

    // work around for ux-datepicker not properly updating 'touched'
    private subscribeControlValueChanges(): Subscription {
        return this.control.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this._onDestroy$),
        ).subscribe(() => {
            this.markInvalidControlAsTouched();
        });
    }

    // work around for ux-datepicker not properly updating 'touched'
    private markInvalidControlAsTouched() {
        if (this.control.status === 'INVALID' && !this.isReadOnly
            && (this.control.hasError('dateOrFutureDate') || this.control.hasError('presentOrFutureDate'))) {
            this.control.markAsTouched();
        }
    }
}
