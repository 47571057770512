import { Directive } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({ selector: '[rtpdTrimInput]' })
export class TrimInputDirective {

    public constructor(ngControl: NgControl) {
        this.trimControlValueAccessor(ngControl.valueAccessor);
    }

    public trimControlValueAccessor(valueAccessor: ControlValueAccessor) {
        const original = valueAccessor.registerOnChange;

        valueAccessor.registerOnChange = (fn: (_: unknown) => void) => {
            return original.call(valueAccessor, (value: unknown) => {
                return fn(typeof value === 'string' ? value.trim() : value);
            });
        };
    }
}
