import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { UxAppShellService, SessionStorageService } from '@eui/core';
import { EuiDialogConfig, EuiDialogService } from '@eui/components/eui-dialog';
import { TranslateService } from '@ngx-translate/core';

import { Severity, UsersService } from '@rtpd/shared';
import * as coreActions from '../actions/core.actions';
import { AWSAuthService, xOperatorId, isEmpty } from '@rtpd/core';
import { ApiErrorHelper } from '../helpers/api-error.helper';

@Injectable()
export class CoreEffects {

    public constructor(
        private actions$: Actions,
        private router: Router,
        private uxAppShellService: UxAppShellService,
        private authService: AWSAuthService,
        private apiErrorHelper: ApiErrorHelper,
        private storageService: SessionStorageService,
        private euiDialogService: EuiDialogService,
        private translateService: TranslateService,
        private usersService: UsersService,
    ) {
    }

    public handleError$ = createEffect(() => this.actions$.pipe(
      ofType(coreActions.HANDLE_ERROR),
      tap((action: coreActions.HandleErrorAction) => {
        const err =  action.payload;
        console.error(err);

        if (!navigator?.onLine) {
            err.message = this.translateService.instant('error.internet.connection.message');
        } else if(!this.excludeAccessErrorCheck(this.router.url)) {
            this.checkAccessError(err.error);
        }

        if (!action.skip) {
            this.uxAppShellService.growl({
                summary: 'ERROR',
                severity: err.severity != null ? Severity[err.severity] : 'danger',
                detail: `
                    ${err.message ? this.apiErrorHelper.parse(err.message) : ''}<br><br>
                    ${err.error && err.error.message ? err.error.message : ''}<br>
                    ${err.error && err.error.error && err.error.error.description ? err.error.error.description : ''}
                `,
            }, undefined, undefined, 20000);
        }
      })), { dispatch: false });

    public navigate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(coreActions.NAVIGATE),
                tap((action: coreActions.NavigateAction) => {
                    this.router.navigate(action.payload.route, { state: action.payload.state });
                }),
            ),
        { dispatch: false },
    );

    public signOut$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(coreActions.SIGN_OUT),
                map(() => {
                    this.authService.signOut();
                }),
            ),
        { dispatch: false },
    );

    private excludeAccessErrorCheck(url: string): boolean {
        return ['/account/create', '/registration', '/scan'].some(path => url?.includes(path));
    }

    private isAccountSelect(url: string): boolean {
        return url?.includes('/account/select');
    }

    private checkAccessError(error) {
        if (error?.headers?.['x-amzn-errortype'] || error?.status === 400
                || error?.status === 403 || error?.status === 404 || error?.status == null) {
            this.usersService.clearSelfCache();
            this.usersService.getSelf().subscribe({
                next: (user) => {
                    if (user.registered === false) {
                        this.openAccessErrorMessageBox();
                    } else if (user.operatorDetails?.length === 0) {
                        this.openAccessErrorMessageBox();
                    } else if (!this.isAccountSelect(this.router.url)) {
                        const operatorId = this.storageService.get(xOperatorId);
                        if (isEmpty(operatorId) || user.operatorDetails?.every(o => o.id !== operatorId)) {
                            this.openAccessErrorMessageBox();
                        }
                    }
                },
                error: () => this.openAccessErrorMessageBox(),
            });
        }
    }

    private openAccessErrorMessageBox() {
        const dialogConfig = new EuiDialogConfig({
            title: this.translateService.instant('error.access.denied.message.title'),
            content: this.translateService.instant('error.access.denied.message'),
            acceptLabel: this.translateService.instant('error.access.denied.button.label'),
            accept: () => this.authService.signOut(),
        });
        this.euiDialogService.openDialog(dialogConfig);
    }
}
