<div class="rtpd-form-formgroup-displayed col-md-9">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.internalReferenceNumber.label' | translate }}">
        <ux-form-control rtpdTrimInput [formControl]="driverInternalReferenceNumber" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
            maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
            placeholderLabel="{{ 'screens.driver.management.driver.details.internalReferenceNumber.label' | translate }}">
        </ux-form-control>
    </ux-form-group>
    <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverInternalReferenceNumber.touched">
        <ux-control-feedback *ngIf="driverInternalReferenceNumber.hasError('internalReferenceNumberExists')" typeClass="danger">
            {{ 'screens.driver.management.driver.details.internalReferenceNumber.validation.error' | translate }}
        </ux-control-feedback>
    </div>
</div>

<div class="rtpd-form-formgroup-displayed col-md-9">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.firstName.label' | translate }}" [isRequired]="!isReadOnly">
        <ux-form-control rtpdTrimInput [formControl]="driverLatinFirstName" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
            maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
            placeholderLabel="{{ 'screens.driver.management.driver.details.firstName.label' | translate }}">
        </ux-form-control>
    </ux-form-group>
    <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverLatinFirstName.touched">
        <ux-control-feedback *ngIf="driverLatinFirstName.hasError('required')" typeClass="danger">
            {{ 'screens.driver.management.driver.details.firstName.validation.error.required' | translate }}
        </ux-control-feedback>
        <ux-control-feedback *ngIf="driverLatinFirstName.hasError('latinChars')" typeClass="danger">
            {{ 'screens.driver.management.driver.details.firstName.validation.error.latinChars' | translate }}
        </ux-control-feedback>
    </div>
</div>

<div class="rtpd-form-formgroup-displayed col-md-9" *ngIf="!isReadOnly || isReadOnly && driverNonLatinFirstName.value">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.nonLatinFirstName.label' | translate }}">
        <ux-form-control rtpdTrimInput [formControl]="driverNonLatinFirstName" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
            maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
            placeholderLabel="{{ 'screens.driver.management.driver.details.nonLatinFirstName.label' | translate }}">
        </ux-form-control>
    </ux-form-group>
</div>

<div class="rtpd-form-formgroup-displayed col-md-9">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.lastName.label' | translate }}" [isRequired]="!isReadOnly">
        <ux-form-control rtpdTrimInput [formControl]="driverLatinLastName" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
            maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
            placeholderLabel="{{ 'screens.driver.management.driver.details.lastName.label' | translate }}">
        </ux-form-control>
        <ux-control-feedback *ngIf="driverLatinLastName.hasError('latinChars')" typeClass="danger">
            {{ 'screens.driver.management.driver.details.lastName.validation.error.latinChars' | translate }}
        </ux-control-feedback>
    </ux-form-group>
    <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverLatinLastName.touched">
        <ux-control-feedback *ngIf="driverLatinLastName.hasError('required')" typeClass="danger">
            {{ 'screens.driver.management.driver.details.lastName.validation.error.required' | translate }}
        </ux-control-feedback>
    </div>
</div>

<div class="rtpd-form-formgroup-displayed col-md-9" *ngIf="!isReadOnly || isReadOnly && driverNonLatinLastName.value">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.nonLatinLastName.label' | translate }}">
        <ux-form-control rtpdTrimInput [formControl]="driverNonLatinLastName" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
            maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
            placeholderLabel="{{ 'screens.driver.management.driver.details.nonLatinLastName.label' | translate }}">
        </ux-form-control>
    </ux-form-group>
</div>

<div class="rtpd-form-formgroup-displayed col-md-9">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.dateOfBirth.label' | translate }}" [isRequired]="!isReadOnly">
        <ux-datepicker [formControl]="driverDateOfBirth" dateOutputFormat="YYYY-MM-DD" [isReadOnly]="isReadOnly">
        </ux-datepicker>
    </ux-form-group>
    <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverDateOfBirth.touched">
        <ux-control-feedback *ngIf="driverDateOfBirth.hasError('required')" typeClass="danger">
            {{ 'screens.driver.management.driver.details.dateOfBirth.validation.error.required' | translate }}
        </ux-control-feedback>
    </div>
</div>

<div class="rtpd-form-formgroup-displayed col-md-9">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.email.label' | translate }}"
                   infoLabel="{{ isReadOnly ? '' : 'screens.driver.management.driver.details.email.infoLabel' | translate }}">
        <ux-form-control  [formControl]="driverEmail" [isReadOnly]="isReadOnly" [maxlength]="254" [isShowMaxlengthCounter]="false"
            placeholderLabel="{{ 'screens.driver.management.driver.details.email.label' | translate }}">
        </ux-form-control>
    </ux-form-group>
    <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverEmail.touched">
        <ux-control-feedback *ngIf="driverEmail.hasError('email')" typeClass="danger">
            {{ 'screens.common.message.field.valid.email' | translate }}
        </ux-control-feedback>
        <ux-control-feedback *ngIf="driverEmail.hasError('maxlength')" typeClass="danger">
            {{ 'screens.common.message.field.valid.characters.100' | translate }}
        </ux-control-feedback>
    </div>
</div>

<div class="rtpd-form-formgroup-displayed col-md-9">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.licence.label' | translate }}" [isRequired]="!isReadOnly">
        <ux-form-control rtpdTrimInput [formControl]="driverLicenseNumber" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
            maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
            placeholderLabel="{{ 'screens.driver.management.driver.details.licence.label' | translate }}">
        </ux-form-control>
    </ux-form-group>
    <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverLicenseNumber.touched">
        <ux-control-feedback *ngIf="driverLicenseNumber.hasError('required')" typeClass="danger">
            {{ 'screens.driver.management.driver.details.licence.validation.error.required' | translate }}
        </ux-control-feedback>
    </div>
</div>

<div class="rtpd-form-formgroup-displayed col-md-9">
    <ux-form-group label="{{ 'screens.driver.management.driver.details.cardNumber.label' | translate }}">
        <ux-form-control rtpdTrimInput [formControl]="driverCardNumber" [isReadOnly]="isReadOnly"
                         placeholderLabel="{{ 'screens.driver.management.driver.details.cardNumber.label' | translate }}">
        </ux-form-control>
    </ux-form-group>
    <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverCardNumber.touched">
        <ux-control-feedback *ngIf="driverCardNumber.hasError('maxlength') || driverCardNumber.hasError('minlength')" typeClass="danger">
            {{ 'screens.common.message.field.valid.characters.16' | translate }}
        </ux-control-feedback>
        <ux-control-feedback *ngIf="driverCardNumber.hasError('cardNumberExists')" typeClass="danger">
            {{ 'screens.common.message.field.valid.first.14.characters' | translate }}
        </ux-control-feedback>
    </div>
</div>

