import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '../../core/helpers';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'documentType' })
export class DocumentTypePipe implements PipeTransform {

  public transform(documentType: string): string {
    return isEmpty(documentType) ? '' :
        'screens.common.document.type.' + documentType.toLowerCase();
  }
}
