import { User } from '@rtpd/shared';

export class UsersSearchResult {

    public count: number;
    public items:  Array<User>;
    public lastEvaluatedKey: string;

    public constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}
